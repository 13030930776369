import React from 'react'

import { Layout, Header } from '/src/components'

const Product = () => {
  return (
    <Layout>
      <Header />
      <section>
        <div className='wrapper'>
          <h2>Fotowoltaika</h2>
        </div>
      </section>
    </Layout>
  )
}

export default Product
